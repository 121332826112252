import Helmet from "react-helmet"
import React from "react"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"


export default function ThankYou() {
  return(
    <>
    <Helmet>
      <style type="type/css">{`


        `}
        </style>
        <title>Thank You!</title>
      </Helmet>
    <section>

    </section>
    </>
  )
}
